import { styled } from '@mui/material';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export const TeamPageStyled = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  scrollbarWidth: 'none',
  padding: theme.spacing(3),
}));

export const ItemStyled = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  textAlign: 'center',
  width: theme.spacing(90),
  height: theme.spacing(24),
  margin: 'auto',

  [theme.breakpoints.down('md')]: {
    width: theme.spacing(58),
  },
}));

export const GridContainerStyled = styled(Grid)({
  alignItems: 'center',
  justifyContent: 'center',
});

export const TypographyStyled = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: theme.spacing(5),

  '.MuiSvgIcon-root': {
    padding: theme.spacing(1),
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.dark,
    fontSize: theme.spacing(5),
    marginLeft: theme.spacing(2),
    borderRadius: theme.spacing(1),

    ':hover': {
      backgroundColor: theme.palette.blue.main,
      cursor: 'pointer',
    },
  },

  [theme.breakpoints.down('lg')]: {
    fontSize: theme.spacing(4),
  },
}));
