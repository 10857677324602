import { memo } from 'react';
import phone from 'assets/phone-icon.png';
import linkedin from 'assets/linkedin.png';

import { AboutTabStyled } from 'containers/main/app-bar/Team/styled';

const TeamTab = ():JSX.Element => (
  <AboutTabStyled>
    <section className="team scroll-container">
      <div className="container">
        <h2 className="team__headline scroll-element js-scroll fade-in-bottom-1">Our Team</h2>
        <div className="team__two-col">
          <div className="team__member member member--exec scroll-element js-scroll fade-in-bottom-2">
            <h3 className="member__name">Webster Hughes, PhD</h3>
            <h4 className="member__role">Co-Founder, Managing Partner</h4>
            <div
              className="member__creds-container js-member-content"
              data-member="webster"
            >
              <ul className="member__credentials">
                <li className="member__credential">
                  <h5>Responsibilities:</h5>
                  {' '}
                  Product Development, System Architecture, Software, IP, Business
                  Development, Client Analytics
                </li>
                <li className="member__credential">
                  <h5>Background:</h5>
                  {' '}
                  Hughes started his career with a PhD in Mathematical Physics Princeton
                  University
                  and two major scientific publications while in his early twenties. In 1986, Dr. Hughes left
                  academia
                  and joined Salomon Brothers to develop the first generation of valuation and risk models for
                  Mortgage-Backed Securities. Subsequent positions include Senior Managing Director at Bear
                  Stearns,
                  Managing Director First Union Capital Markets, and Proprietary Consultant to Bank of
                  America. For the
                  past decade, Hughes’ work has been focused extensively on Freddie Mac Multifamily CMBS
                  Investments.
                  This work led to consulting projects using the Freddie Mac Multifamily Data and then to
                  Multifamily
                  Comps LLC.
                </li>
              </ul>
            </div>
            <hr />
            <div className="member__interactives">
              <div className="member__contact">
                <div className="member__phone-flex">
                  <a className="member__phone" href="tel:+17042006305">
                    <img
                      src={phone}
                      alt="Phone icon"
                    />
                  </a>
                  <a href="tel:+17042006305" className="show-for-d-only">(704) 200-6305</a>
                </div>
                <a
                  className="member__profile-link"
                  target="_blank"
                  href="https://www.linkedin.com/in/webster-hughes-phd/"
                  rel="noreferrer"
                >
                  <img
                    className="member__linked-in"
                    src={linkedin}
                    alt="LinkedIn Icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="team__member member member--exec scroll-element js-scroll fade-in-bottom-3">
            <h3 className="member__name">John Baczewski, CPA, CRE</h3>
            <h4 className="member__role">Co-Founder, Partner</h4>
            <div
              className="member__creds-container js-member-content"
              data-member="john"
            >
              <ul className="member__credentials">
                <li className="member__credential">
                  <h5>Responsibilities:</h5>
                  {' '}
                  Institutional Real Estate Expertise, Product Development,
                  Business
                  Development
                </li>
                <li className="member__credential">
                  <h5>Background:</h5>
                  {' '}
                  Baczewski founded and for the past 16 years has served as President of
                  Real
                  Estate Fiduciary Services LLC (REFS), an independent provider of fiduciary services and
                  strategic
                  consulting to institutional investors. Previously, Mr. Baczewski worked in senior
                  institutional real
                  estate investment, finance, and operating positions, as well as 10 years of Big Four public
                  accounting. Mr. Baczewski has served in numerous industry associations including as Chairman
                  of the
                  Institutional Real Estate Reporting Standards Board sponsored by National Council of Real
                  Estate
                  Investment Fiduciaries (NCREIF) and Pension Real Estate Association (PREA). In 2017, Mr.
                  Baczewski
                  contracted with Dr. Hughes to use the Freddie Mac Multifamily Data to perform Operating
                  Performance
                  Benchmark Analyses for several large institutional clients. This business launched
                  Multifamily Comps
                  LLC.
                </li>
              </ul>
            </div>
            <hr />
            <div className="member__interactives">
              <div className="member__contact">
                <div className="member__phone-flex">
                  <a className="member__phone" href="tel:+19788873750">
                    <img
                      src={phone}
                      alt="Phone icon"
                    />
                  </a>
                  <a href="tel:+19788873750" className="show-for-d-only">(978) 887-3750</a>
                </div>
                <a
                  className="member__profile-link"
                  target="_blank"
                  href="https://www.linkedin.com/in/johnbaczewski/"
                  rel="noreferrer"
                >
                  <img
                    className="member__linked-in"
                    src={linkedin}
                    alt="LinkedIn Icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="team__three-col">
          <div className="team__member member scroll-element js-scroll fade-in-bottom-5">
            <h4 className="member__name">Webster Hughes IV</h4>
            <h5 className="member__role">Co-Founder, Partner</h5>
            <hr />
            <div className="member__interactives">
              <a
                className="member__profile-link"
                target="_blank"
                href="https://www.linkedin.com/in/webhughes/"
                rel="noreferrer"
              >
                <img
                  className="member__linked-in"
                  src={linkedin}
                  alt="LinkedIn Icon"
                />
              </a>
            </div>
          </div>
          <div className="team__member member scroll-element js-scroll fade-in-bottom-6">
            <h4 className="member__name">Ashley Turso</h4>
            <h5 className="member__role">Business Development</h5>
            <hr />
            <div className="member__interactives">
              <a
                className="member__profile-link"
                target="_blank"
                href="https://www.linkedin.com/in/ashleyturso/"
                rel="noreferrer"
              >
                <img
                  className="member__linked-in"
                  src={linkedin}
                  alt="LinkedIn Icon"
                />
              </a>
            </div>
          </div>
          <div className="team__member member scroll-element js-scroll fade-in-bottom-7">
            <h4 className="member__name">William Hughes</h4>
            <h5 className="member__role">Business Development</h5>
            <hr />
            <div className="member__interactives">
              <a
                className="member__profile-link"
                target="_blank"
                href="https://www.linkedin.com/in/william-hughes-303789151/"
                rel="noreferrer"
              >
                <img
                  className="member__linked-in"
                  src={linkedin}
                  alt="LinkedIn Icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </AboutTabStyled>
);
export default memo(TeamTab);
