export interface ILink {
  text: string;
  route: string;
}

export const TEXT = {
  PORTAL: 'Portal',
} as const;

const LINK_ROUTE = {
  HOME: '',
  PRIVACY: '/privacy',
  TEAM: '/team',
  CONTACT: '/contact',
};
const LINK_TEXT = {
  HOME: 'Home',
  PRIVACY: 'Privacy',
  TEAM: 'Team',
  CONTACT: 'Contact',
};

export const LINKS: ILink[] = [
  {
    text: LINK_TEXT.HOME,
    route: LINK_ROUTE.HOME,
  },
  // TODO return when route will be ready
  // {
  //   text: LINK_TEXT.PRIVACY,
  //   route: LINK_ROUTE.PRIVACY,
  // },
  {
    text: LINK_TEXT.TEAM,
    route: LINK_ROUTE.TEAM,
  },
  {
    text: LINK_TEXT.CONTACT,
    route: LINK_ROUTE.CONTACT,
  },
];

export const SOCIALS = {
  TWITTER: 'https://twitter.com/ThirtyCapitalPG',
  LINKEDIN: 'https://www.linkedin.com/company/thirty-capital-performance-group-1/',
};
