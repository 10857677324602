import './styles.css';

import { ProductTabStyled } from 'containers/main/app-bar/Insights/styled';

const InsightsTab = ():JSX.Element => (
  <ProductTabStyled>
    <section className="research">
      <div className="container">
        <h2 className="research__headline">Insights</h2>
        <h3 className="research__subheadline">
          Timely Insights that Support Market and Property-Level Decisions
        </h3>
        <ul className="research__articles">
          <li className="research__article">
            <a href="/MFC(STATVAL)_AutomatedUnderwriting_042122.pdf" target="_blank">
              STATVAL Automates Income Capitalization Approach, 04/21/22
            </a>
          </li>
          <li className="research__article">
            <a href="/MFC(STATVAL)_UnderwritingExpenseGrowth_Article1.pdf" target="_blank">
              Rising Interest Rates, Low CAP Rates, and Expense Growth,
              05/01/22
            </a>
          </li>
          <li className="research__article">
            <a href="/MFC(STATVAL)_UnderwritingExpenseGrowth_Article2_051622.pdf" target="_blank">
              Inflation and Expense Growth by CMSA, 05/16/22
            </a>
          </li>
          <li className="research__article">
            <a href="/MFC(STATVAL)_UnderwritingExpenseGrowth_Article3_053022.pdf" target="_blank">
              Expense Growth by Operating Statement Line-item, 05/30/22
            </a>
          </li>
          <li className="research__article">
            <a href="/MFC(STATVAL)_UnderwritingProjections_Article4_060922.pdf" target="_blank">
              Underwritten NOI and Levered Return Projections, 06/13/22
            </a>
          </li>
          <li className="research__article">
            <a href="/MFC(STATVAL)_UnderwritingExpenseInflation_Article5_091222.pdf" target="_blank">
              Expense Inflation - Lessons for Investors, 09/12/22
            </a>
          </li>
        </ul>
        <hr />
        <p className="research__text">
          We encourage clients to use the research briefs as templates for
          self-directed analysis using our STATVAL database application.
          Contact us for a demo and log-in.
        </p>
      </div>
    </section>
  </ProductTabStyled>
);

export default InsightsTab;
