import { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { useAuth } from 'containers/login/lib/auth';
import { useSideBarContext } from 'context/hooks';
import BuySellCredits from 'containers/buy-sell-credits';
import InputProperty from 'containers/input-property';
import CMBS from 'containers/CMBS';
import Compsets from 'containers/compsets';
import { LoginRoute } from 'common/components/routes/LoginRoute';
import { PrivateRoute } from 'common/components/routes/PrivateRoute';
import { StartPage } from 'containers/main/body/start-page';
import { NotFound } from 'containers/404/NotFound';
import { IUserData } from 'containers/login/lib/models/ResponseData.interface';
import { SideBarActionType } from 'context/SideBarContext';
import Research from 'containers/research';
import Benchmark from 'containers/benchmark';
import statval from 'containers/statval';
import { Spinner } from '../spinner/Spinner';
import { ROUTE } from './utils/constants';
import { getValuesOfFlattenObjectFrom, isRouteValid } from './utils/helpers';
import { MainStyled } from './utils/styled/styled';

export const Routes = (): JSX.Element => {
  const { state: { sideBarOpen, shouldSideBarBeDisplayed }, dispatch } = useSideBarContext();
  const { user, isLoggingIn, isRegistering } = useAuth();
  const { location: { pathname } } = useHistory();
  const auth = user as IUserData;
  const isValidRoute = isRouteValid(getValuesOfFlattenObjectFrom(ROUTE), pathname);

  useEffect(() => {
    dispatch(
      {
        type: SideBarActionType.ShouldSideBarBeDisplayed,
        shouldSideBarBeDisplayed: isValidRoute || pathname === ROUTE.NOT_FOUND,
      },
    );
  }, [dispatch, isValidRoute, pathname, shouldSideBarBeDisplayed]);

  if (!auth?.sessionId && (isLoggingIn || isRegistering)) return <Spinner size="large" />;

  return (
    <MainStyled $isOpen={sideBarOpen} $noMatchExistedRoutes={shouldSideBarBeDisplayed}>
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route exact path="/">
          <Redirect from="/" to={ROUTE.HOME_DEFAULT} />
        </Route>
        {/* <Route path={ROUTE.HOME.PRODUCTS} exact /> */}
        <Route path={ROUTE.HOME.DATABASE} exact />
        <Route path={ROUTE.HOME.TEAM} exact />
        <Route path={ROUTE.HOME.CONTACT} exact />
        <Route path={ROUTE.HOME.HELP} exact />
        <Route path={ROUTE.HOME.INSIGHTS} exact />
        <Route path={ROUTE.HOME.PRIVACY} exact />
        <Route path={ROUTE.HOME_DEFAULT} component={StartPage} exact />
        <Route path={ROUTE.LOGIN}>
          <LoginRoute token={auth?.sessionId} />
        </Route>
        <Route path={ROUTE.LOGOUT}>
          <Redirect from={ROUTE.LOGOUT} to={ROUTE.LOGIN} />
        </Route>
        <PrivateRoute path={ROUTE.ACCOUNT.INPUT_PROPERTY_DEFAULT}>
          <Route component={InputProperty} />
        </PrivateRoute>
        <PrivateRoute path={ROUTE.ACCOUNT.COMPSETS.MAP}>
          <Route component={Compsets} />
        </PrivateRoute>
        <PrivateRoute path={ROUTE.ACCOUNT.CMBS}>
          <Route component={CMBS} />
        </PrivateRoute>
        <PrivateRoute path={ROUTE.ACCOUNT.RESEARCH_DEFAULT}>
          <Route component={Research} />
        </PrivateRoute>
        <PrivateRoute path={ROUTE.ACCOUNT.BENCHMARK_DEFAULT}>
          <Route component={Benchmark} />
        </PrivateRoute>
        <PrivateRoute path={ROUTE.ACCOUNT_DEFAULT}>
          <Route component={BuySellCredits} />
        </PrivateRoute>
        {/* ---------------- */}
        <PrivateRoute path={ROUTE.STATVAL}>
          <Route component={statval} />
        </PrivateRoute>
        {/* ---------------- */}
        <Route path={ROUTE.NOT_FOUND} component={NotFound} />
        <Route path="*">
          <Redirect to={ROUTE.NOT_FOUND} />
        </Route>
      </Switch>
    </MainStyled>
  );
};
