import { withRouter } from 'react-router-dom';
import '../Insights/styles.css';
import cycle from 'assets/cycle-graphic-d.png';
import graphic from 'assets/benchmark-graphic.png';
import graphicd from 'assets/benchmark-graphic-d.png';

import { TeamPageStyled } from './utils/styled';

const ProductsTab = (): JSX.Element => (
  <TeamPageStyled>
    <section className="business-plans scroll-container">
      <div className="container">
        <div className="business-plans__content">
          <h2 className="business-plans__headline">
            Annual Asset Management Business Plans
          </h2>
          <h3 className="business-plans__subheadline">
            Clients have used our Operating Performance Benchmark Analysis on
            an annual basis starting in 2017.
          </h3>
          <ul className="business-plans__value-props">
            <li className="business-plans__value-prop">
              Statval Benchmarking reports support annual business planning,
              providing insights into a property’s competitive position.
              Impact of implementing annual business plan items can be
              measured as part of the next planning cycle.
            </li>
            <li className="business-plans__value-prop">
              Statval reports also support annual hold/sell analyses, a critical
              aspect of property annual plans.
            </li>
          </ul>
        </div>
        <img
          className="business-plans__image scroll-element js-scroll slide-left-offset"
          src={cycle}
          alt="Business Plan Cycle Graphic"
        />
      </div>
    </section>
    <section className="statval">
      <div className="container">
        <h2 className="statval__headline">STATVAL™</h2>
        <h3 className="statval__subheadline">Automated Statistical Valuation</h3>
        <p className="statval__text">
          The Statistical Valuation Software Application (STATVAL™)
          provides an automated and cost-effective method to perform
          Property-Level Analytics. Clients use output either on a stand-alone
          basis and/or feed output into Client Asset Management and
          Underwriting Models. STATVAL™ applies the Income Capitalization
          Method in a manner that utilizes verifiable data and statistically
          matches underwriting assumptions used to calculate DSCR and LTV on
          documented Loan Originations. Once the Subject Property has been
          entered into STATVAL™, a Benchmark Underwriting may be executed in
          as little as 5-10 minutes. In addition to Property-Level Analytics,
          STATVAL provides Market-Level Research Dataset Downloads and
          Statistics. These tools provide a cost-effective complement to both
          in-house and external data and analytics.
        </p>
      </div>
    </section>
    <section className="benchmark scroll-container">
      <div className="container">
        <hr />
        <h2 className="benchmark__headline">Benchmarking Process</h2>
        <img
          className="benchmark__image hide-for-d-only scroll-element js-scroll slide-left"
          src={graphic}
          alt="Benchmark Process Graphic"
        />
        <img
          className="benchmark__image show-for-d-only scroll-element js-scroll slide-left"
          src={graphicd}
          alt="Benchmark Process Graphic"
        />
      </div>
    </section>
    <section className="cmbs">
      <div className="container">
        <h2 className="cmbs__headline">
          AGENCY CMBS SURVEILLANCE
          <br />
          SUBORDINATE AND CONTROLLING CLASS INVESTMENTS
        </h2>
        <p className="cmbs__text">
          The STATVAL™ CMBS Surveillance Module is our most advanced
          application. The Module is a direct outgrowth of over a decade of
          experience in proprietary investment and risk management analytics
          related to FREMF Series K Controlling Class Tranches. The Module
          enables owners, buyers, sellers, and brokers of FREMF Series K
          Controlling Class Tranches to quickly obtain a reliable current LTV,
          DSCR, and levered income return on each asset. This allows users to
          identify loans that are likely to require capital to pay debt
          service, fund capital expenditures, or refinance. The user can then
          import identified properties into STATVAL™ Property-Level Analytics
          templates for a deep-dive underwriting.
        </p>
      </div>
    </section>
  </TeamPageStyled>
);

export default withRouter(ProductsTab);
